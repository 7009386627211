<template>
    <v-sheet width="100%" class="pb-2 pt-3">
        <v-row>
            <v-col class="py-3" cols=3>
                <v-select :disabled=readOnlyMode :readonly=readOnlyMode hide-details v-model="myValue.chkCreateNewColumn" item-text="text" item-value="value" :items="[{'text':'Computed from columns', value:true},{'text':'Use existing column',value:false}]" :label=label dense></v-select>
            </v-col>
            <v-col v-if="myValue.chkCreateNewColumn == false" class="py-3" cols=3>
                <v-select :disabled=readOnlyMode :readonly=readOnlyMode hide-details="auto" 
                    v-model="myValue.columnName"
                    :items="headers"
                    rulesX="columnNameRules"
                    label="Column selector"
                    disable-lookup
                    dense
                >
                </v-select>
                
            </v-col>
            <v-col class="pt-0" v-if="myValue.chkCreateNewColumn == true" cols=9>
                <v-row class="mt-0" >
                    <v-col v-if="needName" class="pt-0" cols=3>
                        <v-text-field  hide-details="auto" :readonly=readOnlyMode v-model="myValue.name" rulesX="nameRules" class="py-1 shrink" label="Name">
                            <!-- <template slot="prepend"><span class="required">*</span></template> -->
                        </v-text-field>
                    </v-col>
                    <v-col class="pt-0" :cols=needName?9:12>
                        <v-row>
                            <v-col cols="6">
                        <v-text-field ref="tf" width="100%" xstyle="display:inline-block;" :readonly=readOnlyMode @click:append="showHelp = !showHelp" _append-icon="help"
                            v-model="myValue.value" rulesX="valueRules" hide-details="auto" class="py-1 shrink" label="Value">
                        </v-text-field>
                            </v-col>
                            <v-col class="pt-6 pl-0" cols="6">
                                <v-icon id="menu" small @click="menu=!menu">menu</v-icon>
                                <span v-if="myValue.value" class="pl-5" style="font-style:italic;">Ex. {{renderedValue}}</span> 
                                <KCSearchableSelect @select="headerSelect" attachID="menu" :height=200 v-model="menu" :listData=headers></KCSearchableSelect>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- [{{isValid()}}]{{myValue}} -->

    </v-sheet>
</template>
<script>
import csvUtils from "../services/KCCSVUtils.js";
import KCSearchableSelect from './KCSearchableSelect.vue'
export default {
    components: {
        KCSearchableSelect
    },    
    props: {
        value: {
            default() {
                return {
                    name: "",
                    value: "",
                    columnName: "",
                    chkCreateNewColumn: false,
                }
            }
        },
        headers: {
            default(){
                return [];
            } 
        },
        sampleData:{
            default(){
                return [];
            } 
        },
        label: {
            default: "Label"
        },
        needName: {
            default: true,
        },
        readOnlyMode:{
            default: false,
        }
    },
    data() {
        return {
            myValue: {},
            showHelp: false,
            //menuValue: "",
            menu: false,
            //renderCount: 0,
        }
    },
    computed:{
        renderedValue(){
            //this.renderCount;
            if(this.hasValue){
                var subCommand = csvUtils.createSubsitiueCommand(this.myValue.value,this.headers);
                console.log(subCommand);
                var value = csvUtils.subsituteValue(this.sampleData,subCommand);
                console.log(value);
                return value;
            }
            return "";
        },
        hasValue(){
            if(this.myValue.value){
                return true;
            }
            return false;
        }
    },
    mounted() {
        this.myValue = this.value;
        // window.addEventListener('keypress', (e)=>{
        //     if(this.menuValue){
        //         console.log('keypress',e);//v-list-item--highlighted
        //         console.log("lstItem",this.$refs.lstItem)
        //         for(let x in this.$refs.lstItem){
        //             let item =  this.$refs.lstItem[x];
        //             console.log(x,item._vnode.parent.data,item.$el);
        //             if(item._vnode.parent.data.key.indexOf(e.key)==0){
        //                 item.toggle();
        //                 break;
        //             }
        //         }
        //     }
        // });
    },
    methods: {
        headerSelect(item){
            if(!this.myValue.value){
                this.myValue.value = "";
            }
            let insertValue = `{${item}}`;
            let len = this.myValue.value.length;
            let pos = this.$refs.tf.$refs.input.selectionStart;
            //console.log(pos);
            if (pos === undefined) {
                this.myValue.value += insertValue;
            }else{
                const before = this.myValue.value.substr(0, pos)
                const after = this.myValue.value.substr(pos, len)
                this.myValue.value = before + insertValue + after
            }
            
            //this.renderCount += 1;
        },
        isValid(){
            if(this.myValue.chkCreateNewColumn == false){
                if(this.myValue.columnName){
                    return true;
                }
            }else{
                if(!this.myValue.value) return false;
                if(this.needName == false) return true;
                if(this.myValue.name){
                    return true;
                }
                // if((this.myValue.name || (this.needName == false)) && this.myValue.value){
                //     return true;
                // }
            }
            return false;
        },
        init(){
            this.myValue = {
                name: "",
                value: "",
                columnName: "",
                chkCreateNewColumn: false,
            }

        }
        // toggleCheckbox() {
        //     this.checkbox = !this.checkbox
        //     this.$emit('input', this.checkbox)
        //     this.$emit('change', this.checkbox)
        // }
    },
    watch: {
        value(newValue){
            if(newValue != this.myValue){
                this.myValue = newValue;
            }
        }
    }
}
</script>