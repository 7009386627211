<template>
  <div>
    <div class="prop-line prop-title">
        <span>{{title}}</span>
        <span v-if="addButton" @click="addClick()" style="float: right;margin-right: 5px;margin-top: -5px;cursor: pointer;font-size: 1.5em;">+</span>
    </div>
        <div v-if="(hideLabel == false)" class="mt-3 prop-line">
        <v-text-field
            v-model="input.label"
            label="Label"
            clearable dense 
            counter
            :maxlength="maxLabel"
        >
        </v-text-field>
        </div>
        <div class="prop-line">
        <v-text-field
            v-model="input.value"
            label="Value"
            clearable dense counter
            :maxlength="maxValue"
        >
        </v-text-field>
        </div>
        <div v-if="(hideKey == false)" class="prop-line">
        <v-text-field
            v-model="input.key"
            label="Key"
            clearable dense
        >
        </v-text-field>
        </div>
  </div>
</template>
<script>
export default {
    props: {
        value: {
            default: {
                label: "",
                value: "",
                key: "",
            }
        },
        title: {
            default: "Title",
        },
        addButton:{
            default: false,
        },
        maxLabel: {
            default: 10,
        },
        maxValue: {
            default: 20,
        },
        hideLabel: {
            default: false,
        },
        hideKey: {
            default: false,
        }        
    
    },   
    data() {
        return {
            input: {
                label: "",
                value: "",
                key: "",
            }
        }
    },
    methods:{
        addClick(){
            this.$emit("addClick",this);
        },
        correctInput(){
            if(this.input.label == null) this.input.label = "";
            if(this.input.value == null) this.input.value = "";
            if(this.input.key == null) this.input.key = "";
        }
    },
    mounted() {
        this.input = {... this.value};
    },
    watch: {
        value: {
            deep: true,
            handler(){
                //console.log("value",this.value);
                this.input = {... this.value};
            }
        },
        "input.label" : {
            handler(){
                this.correctInput();
                this.$emit("input",this.input);
            }
        },
        "input.value" : {
            handler(){
                this.correctInput();
                this.$emit("input",this.input);
            }
        },
        "input.key" : {
            handler(){
                this.correctInput();
                this.$emit("input",this.input);
            }
        }        

    }
}
</script>