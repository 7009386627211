<template>
    <v-dialog v-model="dialog" width="800">
        <v-container class="kc-image-cropper" style="zheight: 500px; width: 100%;background: #f5f5f5;border-radius: 10px;">
            <v-row>
                <div class="prop-line prop-title">
                    <span>Image editor</span>
                    <span style="float: right;display: inline-block;cursor: pointer;" @click="dialog=false;">X</span>
                </div>
                
            </v-row>
            <v-row>
                <v-col cols="7" style="position:relative;">
                <croppa class="ma-2" v-model="myCroppa"
                    :width="400"
                    :height="380"
                    @draw="onDraw"
                    @new-image-drawn="onNewImage"
                    :initial-image="src">
                ></croppa>
                <div class="overlay">
                    <div class="cropArea" :style="cropAreaStyle">
                    </div>
                </div>
                </v-col>
                <v-col  class="pt-5" cols="5" style="position:relative;">
                    <div v-if="myCroppa.scaleRatio">
                        <!-- <button @click="generateImage">Ok</button>
                        <button @click="test">Test</button> -->
                        <!-- <img v-if="imgUrl" :src="imgUrl" width="200" /> -->
                        <div><label>Preview</label></div>
                        <div style="overflow: auto;"><canvas  id="canvas" style="margin-left: auto;margin-right: auto;display: block;"></canvas></div>
                        <div v-if="cropArea.maxWidth" class="pr-5">
                            <v-slider @change="onDraw" dense hide-details v-model="cropArea.width" label="width" :max="cropArea.maxWidth" :min="cropArea.minWidth" :step=1></v-slider>
                        </div>                            
                        <div v-if="cropArea.maxHeight" class="pr-5">
                            <v-slider @change="onDraw" dense hide-details v-model="cropArea.height" label="height" :max="cropArea.maxHeight" :min="cropArea.minHeight" :step=1></v-slider>
                        </div>                            

                        <div class="pr-5">
                            <!-- {{sliderValue}} -->
                            <v-slider dense hide-details v-model="sliderValue" label="Zoom" :max="sliderMax" :min="sliderMin" :step=0.001></v-slider>
                            <v-btn dense class="mr-2" @click="myCroppa.rotate(1)">Rotate</v-btn>
                            <v-btn dense class="mr-2" @click="myCroppa.flipX()">FlipX</v-btn>
                            <v-btn dense class="mr-2" @click="myCroppa.flipY()">FlipY</v-btn>
                        </div>
                    </div>
                    <div>
                    
                    </div>
                </v-col>
            </v-row>
            <v-row class="pt-2 ma-0">
            <v-btn @click="cropClik()" :disabled="myCroppa.scaleRatio == undefined || myCroppa.scaleRatio == 0" style="right: -700px;position: relative;">Done</v-btn>                                
            </v-row>
        </v-container>
    </v-dialog>
</template>
<style>
.overlay {
    width: 400px;
    height: 380px;
/*    border: solid 1px red;*/
    position: absolute;
    top: 20px;
    left: 20px;
    pointer-events:none;
}
.cropArea {
    /* width: 300px;
    height: 180px; */
    border: solid 1px #1976d2;
    /* top: 100px; */
    position: relative;
    /* left: 50px; */
}
</style>
<script>
// import VueCropper from 'vue-cropperjs';
// import 'cropperjs/dist/cropper.css';
export default {
    name: "Kwang-Image-Cropper",
    components: {
        // VueCropper
    },
    props: {
        value: {
            default: false,
        },
        width: {
            default: 300,
        },
        height: {
            default: 180,
        },
        maxWidth: {
            default: null,
        },
        maxHeight: {
            default: null,
        },
        src:{
            default:null,
        }
    },
    data() {
        return {
            myCroppa: {},
            dialog: this.value,
            // srcImage: "https://cdn.pub1.passkit.io/images/5rzPsGAxOP489Y2xOhv7Lh/strip/strip@2x.png",
            imgUrl: null,
            cropArea: {
                // x: 50 ,
                // y: 100,
                width: this.width,
                minWidth: this.width,
                maxWidth: this.maxWidth,
                height: this.height,                
                minHeight: this.height,
                maxHeight: this.maxHeight,
            },
            viewArea: {
                width: 400,
                height: 380,
            },
            sliderMax:1,
            sliderMin:0,
            sliderValue: 0.5,
        };
    },
    computed: {
        cropAreaStyle() {
            return {
                width: `${this.cropArea.width}px`,
                height: `${this.cropArea.height}px`,
                top: `${this.cropAreaY}px`,
                left: `${this.cropAreaX}px`,
            }
        },
        cropAreaY(){
            return (this.viewArea.height - this.cropArea.height)/2
        },
        cropAreaX(){
            return (this.viewArea.width - this.cropArea.width)/2
        }

    },
    updated(){
        // console.log("updated",this.dialog,this.value,this.myCroppa,this.myCroppa.hasImage())
    },
    methods: {
        // generateImage: function() {
        //     let url = this.myCroppa.generateDataUrl()
        //     if (!url) {
        //         alert('no image')
        //         return
        //     }
        //     this.imgUrl = url;
        //     console.log(url);
        // },
        onNewImage(){
            console.log("onNewImage",this.myCroppa.imgData,this.myCroppa.scaleRatio);
            let width = this.myCroppa.imgData.width;
            let height = this.myCroppa.imgData.height;
            this.sliderValue = this.myCroppa.scaleRatio;    // zoome value
            this.sliderMin = this.myCroppa.scaleRatio / 12;
            this.sliderMax = this.myCroppa.scaleRatio * 4;
        },
        onDraw(ctxPreview){
            const originalWidth = this.myCroppa.outputWidth;
            const originalHeight = this.myCroppa.outputHeight;
            var metadata = this.myCroppa.getMetadata();
            console.log(metadata)
            this.sliderValue = Math.round(this.myCroppa.scaleRatio*1000)/1000;
            // if(this.sliderValue < this.sliderMin) this.sliderMin = this.sliderValue;
            // if(this.sliderValue > this.sliderMax) this.sliderMax = this.sliderValue;
            var croppaCanvas = this.myCroppa.getCanvas();
            const canvas = document.getElementById('canvas'); 
            const ctx = canvas.getContext('2d');
            let self = this;
            let scaleX = originalWidth/self.viewArea.width;
            let scaleY=  originalHeight/self.viewArea.height;
            console.log("scaleX",scaleX);
            
            canvas.width = self.cropArea.width;
            canvas.height = self.cropArea.height;
            ctx.drawImage(croppaCanvas,self.cropAreaX*scaleX,self.cropAreaY*scaleY,
                    self.cropArea.width*scaleX,self.cropArea.height*scaleY, 0, 0, canvas.width, canvas.height);

            //ctx.drawImage(croppaCanvas,self.cropArea.x,self.cropArea.y,canvas.width,canvas.height,0, 0, canvas.width, canvas.height);
            // this.test();
        },
        cropClik(){
            const canvas = document.getElementById('canvas');
            var dataUrl = canvas.toDataURL('image/png');
            //console.log(dataUrl);
            this.$emit("crop",{dataUrl,width:this.cropArea.width});
            this.dialog = false;
        },
        // zoomSliderChange(value){
        //     this.myCroppa.scaleRatio = value;
        // }
        chooseFile() {
            //console.log("upload");
            //this.myCroppa.chooseFile();
            // if (!this.myCroppa.hasImage()) {
            //     alert('no image to upload')
            //     return
            // }
            // this.myCroppa.generateBlob((blob) => {
            //     var fd = new FormData()
            //     fd.append('file', blob, 'filename.jpg')
            //     fd.append('other', 'blahblahblah')
            //     $.ajax({
            //     url: 'http://www.xxx.com/api/upload',
            //     data: fd,
            //     type: 'POST',
            //     processData: false,
            //     contentType: false,
            //     success: function(data) {
            //         alert(data)
            //     }
            //     })
            // })
        },        

    },

    created() {
    },
    destroyed() {
    },
    mounted() {
        // if(!this.myCroppa.hasImage()){
        //     this.myCroppa.chooseFile();
        // }
//         this.$nextTick(() => {
//             this.myCroppa.chooseFile();
// // this.root.style.setProperty("--bg-color", newValue);
//         })
    },
    beforeUnmount() { },
    watch: {
        value: {
            handler: function(newValue, oldValue) {
                console.log("value handler");
        //         this.dialog = newValue
        //         console.log("value",newValue);
        //         if(newValue){
        //             console.log("value",newValue);
        //             if(!this.myCroppa.hasImage()){
        //                 this.myCroppa.chooseFile();
        //             }
        //         //     this.cropArea.width =  this.width;
        //         //     this.cropArea.height =  this.height;
        //         }
            }            
        },
        dialog(newValue, oldValue) {
            this.$emit("input", newValue); // v-model need this to tell parent that value have change
        },
        sliderValue: {
            handler: function(newValue, oldValue) {
                // this.dialog = newValue
                this.myCroppa.scaleRatio = (Math.round(newValue*1000))/1000;
                console.log("sliderValue",this.myCroppa.scaleRatio);
            },
        }
    },
};
</script>